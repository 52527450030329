import React from 'react';
import './App.css';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/core/styles.layer.css';
import 'mantine-datatable/styles.layer.css';
import '@mantine/charts/styles.css';
import {MantineProvider, MantineThemeProvider} from "@mantine/core";
import {Shell} from "./components/shell/Shell";
import {useConfigStore} from "./store/configStore";
import {ModalsProvider} from "@mantine/modals";

function App() {
    const config = useConfigStore();

    return (
        <MantineProvider forceColorScheme={config.theme}>
            <MantineThemeProvider/>
            <ModalsProvider>
                <Shell/>
            </ModalsProvider>
        </MantineProvider>
    );
}

export default App;
