import type { app__schema__subscriber__Add } from '../models/app__schema__subscriber__Add';
import type { app__schema__subscriber__Get } from '../models/app__schema__subscriber__Get';
import type { Subscriber } from '../models/Subscriber';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export type TDataDeleteSubscriberByEmailApiV1NewsletterSubscriberEmailDelete = {
                email: string
            }
export type TDataGetSubscriberByEmailApiV1NewsletterSubscriberEmailGet = {
                email: string
            }
export type TDataAddSubscriberApiV1NewsletterSubscriberPost = {
                requestBody: app__schema__subscriber__Add
            }

export class NewsletterService {

	/**
	 * Send Newsletter To Subscribers
	 * Send a newsletter
	 * @returns unknown Successful Response
	 * @throws ApiError
	 */
	public static sendNewsletterToSubscribersApiV1NewsletterPost(): CancelablePromise<unknown> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v1/newsletter',
		});
	}

	/**
	 * Delete Subscriber By Email
	 * Delete a subscriber from newsletter
	 * @returns app__schema__subscriber__Get Successful Response
	 * @throws ApiError
	 */
	public static deleteSubscriberByEmailApiV1NewsletterSubscriberEmailDelete(data: TDataDeleteSubscriberByEmailApiV1NewsletterSubscriberEmailDelete): CancelablePromise<app__schema__subscriber__Get> {
		const {
email,
} = data;
		return __request(OpenAPI, {
			method: 'DELETE',
			url: '/api/v1/newsletter/subscriber/{email}',
			path: {
				email
			},
			errors: {
				422: `Validation Error`,
			},
		});
	}

	/**
	 * Get Subscriber By Email
	 * Get subscriber by mail address
	 * @returns app__schema__subscriber__Get Successful Response
	 * @throws ApiError
	 */
	public static getSubscriberByEmailApiV1NewsletterSubscriberEmailGet(data: TDataGetSubscriberByEmailApiV1NewsletterSubscriberEmailGet): CancelablePromise<app__schema__subscriber__Get> {
		const {
email,
} = data;
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/newsletter/subscriber/{email}',
			path: {
				email
			},
			errors: {
				422: `Validation Error`,
			},
		});
	}

	/**
	 * Add Subscriber
	 * Add a subscriber to newsletter
	 * @returns app__schema__subscriber__Get Successful Response
	 * @throws ApiError
	 */
	public static addSubscriberApiV1NewsletterSubscriberPost(data: TDataAddSubscriberApiV1NewsletterSubscriberPost): CancelablePromise<app__schema__subscriber__Get> {
		const {
requestBody,
} = data;
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v1/newsletter/subscriber',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`,
			},
		});
	}

	/**
	 * Get All Subscribers
	 * Get all subscribers
	 * @returns Subscriber Successful Response
	 * @throws ApiError
	 */
	public static getAllSubscribersApiV1NewsletterSubscribersGet(): CancelablePromise<Array<Subscriber>> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/newsletter/subscribers',
		});
	}

}