import type { AppUser } from '../models/AppUser';
import type { Body_login_for_access_token_api_v1_user_login_post } from '../models/Body_login_for_access_token_api_v1_user_login_post';
import type { ChangePasswordUser } from '../models/ChangePasswordUser';
import type { Token } from '../models/Token';
import type { User } from '../models/User';
import type { UserAdd } from '../models/UserAdd';
import type { UserUpdate } from '../models/UserUpdate';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export type TDataGetUserByUsernameApiV1UserUsernameGet = {
                username: string
            }
export type TDataDeleteUserByUsernameApiV1UserUsernameDelete = {
                username: string
            }
export type TDataUpdateUserByUsernameApiV1UserUsernamePut = {
                requestBody: UserUpdate
username: string
            }
export type TDataUpdatePasswordByUsernameApiV1UserPasswordUsernamePut = {
                requestBody: ChangePasswordUser
username: string
            }
export type TDataUpdateDeactivationDateForUserApiV1UserChangeDateUseridNewDatePut = {
                newDate: string
userid: number
            }
export type TDataToggleActiveUserApiV1UserToggleActiveUseridPut = {
                userid: number
            }
export type TDataAddUserApiV1UserPost = {
                requestBody: UserAdd
            }
export type TDataLoginForAccessTokenApiV1UserLoginPost = {
                formData: Body_login_for_access_token_api_v1_user_login_post
            }

export class UserService {

	/**
	 * Get All Users
	 * Get all users
	 * @returns AppUser Successful Response
	 * @throws ApiError
	 */
	public static getAllUsersApiV1UsersGet(): CancelablePromise<Array<AppUser>> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/users',
		});
	}

	/**
	 * Get User By Username
	 * Get a user by username
	 * @returns User Successful Response
	 * @throws ApiError
	 */
	public static getUserByUsernameApiV1UserUsernameGet(data: TDataGetUserByUsernameApiV1UserUsernameGet): CancelablePromise<User> {
		const {
username,
} = data;
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/user/{username}',
			path: {
				username
			},
			errors: {
				422: `Validation Error`,
			},
		});
	}

	/**
	 * Delete User By Username
	 * Delete a user by username
	 * @returns User Successful Response
	 * @throws ApiError
	 */
	public static deleteUserByUsernameApiV1UserUsernameDelete(data: TDataDeleteUserByUsernameApiV1UserUsernameDelete): CancelablePromise<User> {
		const {
username,
} = data;
		return __request(OpenAPI, {
			method: 'DELETE',
			url: '/api/v1/user/{username}',
			path: {
				username
			},
			errors: {
				422: `Validation Error`,
			},
		});
	}

	/**
	 * Update User By Username
	 * Update user by username
	 * @returns User Successful Response
	 * @throws ApiError
	 */
	public static updateUserByUsernameApiV1UserUsernamePut(data: TDataUpdateUserByUsernameApiV1UserUsernamePut): CancelablePromise<User> {
		const {
requestBody,
username,
} = data;
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/api/v1/user/{username}',
			path: {
				username
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`,
			},
		});
	}

	/**
	 * Update Password By Username
	 * Change password for user by username, only for admins and for themselves aswell
	 * @returns User Successful Response
	 * @throws ApiError
	 */
	public static updatePasswordByUsernameApiV1UserPasswordUsernamePut(data: TDataUpdatePasswordByUsernameApiV1UserPasswordUsernamePut): CancelablePromise<User> {
		const {
requestBody,
username,
} = data;
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/api/v1/user/password/{username}',
			path: {
				username
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`,
			},
		});
	}

	/**
	 * Check If User Is Admin
	 * Check if user is admin
	 * @returns boolean Successful Response
	 * @throws ApiError
	 */
	public static checkIfUserIsAdminApiV1UserCheckIsAdminGet(): CancelablePromise<boolean> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/user/check/isAdmin',
		});
	}

	/**
	 * Update Deactivation Date For User
	 * Change the deactivation date for a user
	 * @returns User Successful Response
	 * @throws ApiError
	 */
	public static updateDeactivationDateForUserApiV1UserChangeDateUseridNewDatePut(data: TDataUpdateDeactivationDateForUserApiV1UserChangeDateUseridNewDatePut): CancelablePromise<User> {
		const {
newDate,
userid,
} = data;
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/api/v1/user/change-date/{userid}/{new_date}',
			path: {
				userid, new_date: newDate
			},
			errors: {
				422: `Validation Error`,
			},
		});
	}

	/**
	 * Toggle Active User
	 * Change the deactivation date for a user
	 * @returns User Successful Response
	 * @throws ApiError
	 */
	public static toggleActiveUserApiV1UserToggleActiveUseridPut(data: TDataToggleActiveUserApiV1UserToggleActiveUseridPut): CancelablePromise<User> {
		const {
userid,
} = data;
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/api/v1/user/toggleActive/{userid}',
			path: {
				userid
			},
			errors: {
				422: `Validation Error`,
			},
		});
	}

	/**
	 * Add User
	 * Add a user
	 * @returns User Successful Response
	 * @throws ApiError
	 */
	public static addUserApiV1UserPost(data: TDataAddUserApiV1UserPost): CancelablePromise<User> {
		const {
requestBody,
} = data;
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v1/user',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`,
			},
		});
	}

	/**
	 * Login For Access Token
	 * @returns Token Successful Response
	 * @throws ApiError
	 */
	public static loginForAccessTokenApiV1UserLoginPost(data: TDataLoginForAccessTokenApiV1UserLoginPost): CancelablePromise<Token> {
		const {
formData,
} = data;
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v1/user/login',
			formData: formData,
			mediaType: 'application/x-www-form-urlencoded',
			errors: {
				422: `Validation Error`,
			},
		});
	}

}