import React, {useState} from "react";
import {Box, Button, Collapse, Group, Text, Title} from '@mantine/core';
import {DataTable} from "mantine-datatable";
import {useQuery} from "@tanstack/react-query";
import {ArchiveService} from "../../client";


export const Archive = () => {
    const {isLoading, data} = useQuery({
        queryKey: ['archive'],
        queryFn: ArchiveService.getArchiveApiV1ArchiveGet,
    });

    const [toggles, setToggles] = useState([false, false, false]);

    function handleToggle(index: number) {
        setToggles((prevToggles) =>
            prevToggles.map((toggle, i) => (i === index ? !toggle : toggle))
        );
    }

    const yearlyDataTable = (year: number, index: number) => {
        if (!data) {
            return <></>;
        }
        const records = data[year];
        const vegetarians: number = records.filter((item) => item.food === 'vegetarian').length;
        const vegan: number = records.filter((item) => item.food === 'vegan').length;
        const mixed: number = records.filter((item) => item.food === 'mixed').length;
        let foodString = "";
        if (vegan > 0) {
            foodString += " ┊ 🌱 ↦ " + vegan;
        }
        if (vegetarians > 0) {
            foodString += " ┊ 🥗 ↦ " + vegetarians;
        }
        if (mixed > 0) {
            foodString += " ┊ 🥩 ↦ " + mixed;
        }
        const shirtSizes: Map<string, number> = new Map();
        records.forEach((item) => {
            if (item.tshirt) {
                if (shirtSizes.has(item.tshirt)) {
                    shirtSizes.set(item.tshirt, (shirtSizes.get(item.tshirt) ?? 0) + 1);
                } else {
                    shirtSizes.set(item.tshirt, 1);
                }
            }
        })
        let shirtString: string = "";
        Array.from(shirtSizes.keys()).forEach((item) => {
            shirtString += item.toUpperCase() + ": " + shirtSizes.get(item) + " ┊ ";
        });
        const ages: Map<number, number> = new Map();
        records.forEach((item) => {
            if (ages.has(item.age)) {
                ages.set(item.age, (ages.get(item.age) ?? 0) + 1);
            } else {
                ages.set(item.age, 1);
            }
        });
        let agesString: string = "";
        Array.from(ages.keys()).forEach((item) => {
            agesString += item + "y: " + ages.get(item) + " ┊ ";
        });
        return (
            <Box key={year} m={2}>
                <Group justify="left" mb={5}>
                    <Button onClick={() => handleToggle(index)} fullWidth variant="outline">{year}</Button>
                    <Box>
                        <Text c="dimmed" mb={10} size="sm">{records.length} Registrations {foodString}</Text>
                        <Text c="dimmed" mb={10} size="sm">T-Shirts: {shirtString}</Text>
                        <Text c="dimmed" mb={10} size="sm">Ages: {agesString}</Text>
                    </Box>
                </Group>
                <Collapse in={toggles[index]} transitionTimingFunction="ease" mt={5}>
                    <DataTable
                        withTableBorder
                        striped
                        highlightOnHover
                        records={records}
                        fetching={isLoading}
                        loaderType='oval'
                        columns={[
                            {
                                accessor: 'notice', title: 'Note', sortable: false,
                                render: ({notice}) => {
                                    if (notice) {
                                        return notice;
                                    } else
                                        return "-";
                                }
                            },
                            {
                                accessor: 'food', title: 'Food', sortable: false,
                                render: ({food}) => {
                                    if (food) {
                                        return food;
                                    } else
                                        return "-";
                                }
                            },
                            {accessor: 'age', title: 'Age', sortable: false},
                            {accessor: 'tshirt', title: 'T-Shirt', sortable: false},
                        ]}
                    />
                </Collapse>
            </Box>);
    }

    if (!data) {
        return <></>;
    }

    const years: number[] = Object.keys(data) as unknown as number[];
    years.sort((a, b) => a < b ? 1 : -1);


    const allTables = years.map((year, index) => {
        return yearlyDataTable(year, index);
    })

    return <>
        <Box mb={5}>
            <Group justify="center" mb={5}>
                <Title fw={250}>Archive</Title>
            </Group>
        </Box>
        {allTables}
        <div key="legend">
            <Box>
                <Group justify="center" m={5}>
                    <Text c="dimmed" size="sm">Legend: 🌱 ↦ Vegan ┊ 🥗 ↦ Vegetarian ┊ 🥩 ↦ Mixed</Text>
                </Group>
            </Box>
        </div>
    </>;
}