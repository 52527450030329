import {
    AppShell,
    Burger,
    Button,
    Image,
    NavLink,
    Space,
    Stack,
} from '@mantine/core';
import {useDisclosure} from '@mantine/hooks';

import {Group} from '@mantine/core';
import {ThemeSwitcher} from "../themeSwitcher/ThemeSwitcher";
import React from "react";
import {Route, Routes} from "react-router-dom";
import {Registrations} from "../registrations/Registrations";
import Login from "../login/Login";
import {useAuthStore} from "../../store/authStore";
import {Users} from "../users/Users";
import {
    IconArchive,
    IconGraph,
    IconList,
    IconNews,
    IconQrcode,
    IconUsers,
} from "@tabler/icons-react";
import {Statistics} from "../statistics/Statistics";
import {Newsletter} from "../newsletter/Newsletter";
import {Archive} from "../archive/Archive";
import {CheckIn} from "../check-in/CheckIn";
import {useQuery} from "@tanstack/react-query";
import {UserService} from "../../client";

export function Shell() {
    const [mobileOpened, {toggle: toggleMobile}] = useDisclosure();
    const [desktopOpened, {toggle: toggleDesktop}] = useDisclosure(true);
    const isLoggedIn = useAuthStore(state => state.isLoggedIn);

    const {isLoading, data} = useQuery({
        queryKey: ['is-admin'],
        queryFn: UserService.checkIfUserIsAdminApiV1UserCheckIsAdminGet
    });
    if (!isLoggedIn && !isLoading) {
        return (
            <Login/>
        );
    }

    const isAdmin = data ?? false;


    return (
        <AppShell
            header={{height: 60}}
            navbar={{
                width: { base: 75, md: 150, lg: 200 },
                breakpoint: 'sm',
                collapsed: {mobile: !mobileOpened, desktop: !desktopOpened},
            }}
            padding="md"
        >
            <AppShell.Header>
                <Group w="100%" gap="xl" justify="space-between">
                    <Group h="100%" px="md">
                        <Burger opened={mobileOpened} onClick={toggleMobile} hiddenFrom="sm" size="sm"/>
                        <Burger opened={desktopOpened} onClick={toggleDesktop} visibleFrom="sm" size="sm"/>
                    </Group>
                    <Image
                        w="50"
                        src="/logo.png"
                    />
                    <Group justify="space-between" mr="sm">
                        <Stack>
                            <Space/>
                            <ThemeSwitcher/>
                            <Space/>
                        </Stack>
                        <Stack>
                            <Space/>
                            <Button size="xs" variant="outline" color="red" onClick={() => {
                                useAuthStore.getState().logout();
                            }}>
                                Logout
                            </Button>
                            <Space/>
                        </Stack>
                    </Group>
                </Group>
            </AppShell.Header>
            <AppShell.Navbar p="md">
                <NavLink
                    href="/registrations"
                    label="Registrations"
                    leftSection={<IconList size="1rem" stroke={1.5}/>}
                />
                {isAdmin && <NavLink
                    href="/users"
                    label="Users"
                    leftSection={<IconUsers size="1rem" stroke={1.5}/>}
                />}
                <NavLink
                    href="/statistics"
                    label="Statistics"
                    leftSection={<IconGraph size="1rem" stroke={1.5}/>}
                />
                <NavLink
                    href="/newsletter"
                    label="Newsletter"
                    leftSection={<IconNews size="1rem" stroke={1.5}/>}
                />
                <NavLink
                    href="/check-in"
                    label="Check In"
                    leftSection={<IconQrcode size="1rem" stroke={1.5}/>}
                />
                <NavLink
                    href="/archive"
                    label="Archive"
                    leftSection={<IconArchive size="1rem" stroke={1.5}/>}
                />
            </AppShell.Navbar>
            <AppShell.Main>
                <Routes>
                    <Route path="/" element={<Registrations/>}/>
                    <Route path="/registrations" element={<Registrations/>}/>
                    { isAdmin ? <Route path="/users" element={<Users/>}/> : <></> }
                    <Route path="/statistics" element={<Statistics/>}/>
                    <Route path="/newsletter" element={<Newsletter/>}/>
                    <Route path="/check-in" element={<CheckIn/>}/>
                    <Route path="/archive" element={<Archive/>}/>
                </Routes>
            </AppShell.Main>
        </AppShell>
    );
}