import type { FoodStat } from '../models/FoodStat';
import type { RegistrationsDates } from '../models/RegistrationsDates';
import type { RegistrationsStat } from '../models/RegistrationsStat';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';



export class StatisticsService {

	/**
	 * Get Tshirts
	 * returns stats of t-shirts of the current and last year
	 * @returns number Successful Response
	 * @throws ApiError
	 */
	public static getTshirtsApiV1StatsTshirtsGet(): CancelablePromise<Record<string, Record<string, number>>> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/stats/tshirts',
		});
	}

	/**
	 * Get Registrations
	 * Get registrations stats
	 * @returns RegistrationsStat Successful Response
	 * @throws ApiError
	 */
	public static getRegistrationsApiV1StatsRegistrationsGet(): CancelablePromise<Array<RegistrationsStat>> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/stats/registrations',
		});
	}

	/**
	 * Get Registration
	 * Get registration stats
	 * @returns RegistrationsDates Successful Response
	 * @throws ApiError
	 */
	public static getRegistrationApiV1StatsRegistrationGet(): CancelablePromise<Array<RegistrationsDates>> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/stats/registration',
		});
	}

	/**
	 * Get Food Stats
	 * Get food stats
	 * @returns FoodStat Successful Response
	 * @throws ApiError
	 */
	public static getFoodStatsApiV1StatsFoodGet(): CancelablePromise<Array<FoodStat>> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/stats/food',
		});
	}

}