import type { Archive } from '../models/Archive';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export type TDataGetArchiveByYearApiV1ArchiveYearGet = {
                year: number
            }

export class ArchiveService {

	/**
	 * Get Archive
	 * @returns Archive Successful Response
	 * @throws ApiError
	 */
	public static getArchiveApiV1ArchiveGet(): CancelablePromise<Record<string, Array<Archive>>> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/archive',
		});
	}

	/**
	 * Move Registrations To Archive
	 * Move registrations to archive
	 * @returns unknown Successful Response
	 * @throws ApiError
	 */
	public static moveRegistrationsToArchiveApiV1ArchivePost(): CancelablePromise<unknown> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v1/archive',
		});
	}

	/**
	 * Get Archive By Year
	 * Get archive by year
	 * @returns unknown Successful Response
	 * @throws ApiError
	 */
	public static getArchiveByYearApiV1ArchiveYearGet(data: TDataGetArchiveByYearApiV1ArchiveYearGet): CancelablePromise<unknown> {
		const {
year,
} = data;
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/archive/{year}',
			path: {
				year
			},
			errors: {
				422: `Validation Error`,
			},
		});
	}

}