import type { app__schema__registration__Add } from '../models/app__schema__registration__Add';
import type { app__schema__registration__Get } from '../models/app__schema__registration__Get';
import type { Registration } from '../models/Registration';
import type { Update } from '../models/Update';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export type TDataGetRegistrationByIdApiV1RegistrationRegIdGet = {
                regId: number
            }
export type TDataDeleteRegistrationByIdApiV1RegistrationRegIdDelete = {
                regId: number
            }
export type TDataUpdateRegistrationByIdApiV1RegistrationRegIdPut = {
                regId: number
requestBody: Update
            }
export type TDataTogglePaidApiV1RegistrationRegIdTogglePaidPut = {
                regId: number
            }
export type TDataAddRegistrationApiV1RegistrationPost = {
                requestBody: app__schema__registration__Add
            }
export type TDataReconfirmApiV1RegistrationReconfirmPost = {
                registrationId?: number
            }
export type TDataCheckInApiV1RegistrationCheckInRegistrationIdGet = {
                registrationId: number
            }
export type TDataConfirmCheckInApiV1RegistrationCheckInRegistrationIdPut = {
                registrationId: number
            }

export class RegistrationService {

	/**
	 * Get All Registrations
	 * Get all registrations
	 * @returns Registration Successful Response
	 * @throws ApiError
	 */
	public static getAllRegistrationsApiV1RegistrationsGet(): CancelablePromise<Array<Registration>> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/registrations',
		});
	}

	/**
	 * Get Registration By Id
	 * Get a registration by ID
	 * @returns app__schema__registration__Get Successful Response
	 * @throws ApiError
	 */
	public static getRegistrationByIdApiV1RegistrationRegIdGet(data: TDataGetRegistrationByIdApiV1RegistrationRegIdGet): CancelablePromise<app__schema__registration__Get> {
		const {
regId,
} = data;
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/registration/{reg_id}',
			path: {
				reg_id: regId
			},
			errors: {
				422: `Validation Error`,
			},
		});
	}

	/**
	 * Delete Registration By Id
	 * Delete a registration by ID
	 * @returns app__schema__registration__Get Successful Response
	 * @throws ApiError
	 */
	public static deleteRegistrationByIdApiV1RegistrationRegIdDelete(data: TDataDeleteRegistrationByIdApiV1RegistrationRegIdDelete): CancelablePromise<app__schema__registration__Get> {
		const {
regId,
} = data;
		return __request(OpenAPI, {
			method: 'DELETE',
			url: '/api/v1/registration/{reg_id}',
			path: {
				reg_id: regId
			},
			errors: {
				422: `Validation Error`,
			},
		});
	}

	/**
	 * Update Registration By Id
	 * Update a registration by ID
	 * @returns app__schema__registration__Get Successful Response
	 * @throws ApiError
	 */
	public static updateRegistrationByIdApiV1RegistrationRegIdPut(data: TDataUpdateRegistrationByIdApiV1RegistrationRegIdPut): CancelablePromise<app__schema__registration__Get> {
		const {
regId,
requestBody,
} = data;
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/api/v1/registration/{reg_id}',
			path: {
				reg_id: regId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`,
			},
		});
	}

	/**
	 * Mark Payments As Delayed
	 * Marks payments as delayed and sends payment reminder
	 * @returns unknown Successful Response
	 * @throws ApiError
	 */
	public static markPaymentsAsDelayedApiV1RegistrationMarkPaymentsPost(): CancelablePromise<unknown> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v1/registration/mark-payments',
		});
	}

	/**
	 * Toggle Paid
	 * Toggle paid status of a registration by ID
	 * @returns app__schema__registration__Get Successful Response
	 * @throws ApiError
	 */
	public static togglePaidApiV1RegistrationRegIdTogglePaidPut(data: TDataTogglePaidApiV1RegistrationRegIdTogglePaidPut): CancelablePromise<app__schema__registration__Get> {
		const {
regId,
} = data;
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/api/v1/registration/{reg_id}/toggle_paid',
			path: {
				reg_id: regId
			},
			errors: {
				422: `Validation Error`,
			},
		});
	}

	/**
	 * Add Registration
	 * Add a registration
	 * @returns app__schema__registration__Get Successful Response
	 * @throws ApiError
	 */
	public static addRegistrationApiV1RegistrationPost(data: TDataAddRegistrationApiV1RegistrationPost): CancelablePromise<app__schema__registration__Get> {
		const {
requestBody,
} = data;
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v1/registration',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`,
			},
		});
	}

	/**
	 * Reconfirm
	 * Resend confirmation mail
 * @param current_user: User that reconfirm the registration
 * @param database: Database session
 * @param registration_id: ID of the registration to check in
 * @return: Registration data
	 * @returns app__schema__registration__Get Successful Response
	 * @throws ApiError
	 */
	public static reconfirmApiV1RegistrationReconfirmPost(data: TDataReconfirmApiV1RegistrationReconfirmPost = {}): CancelablePromise<app__schema__registration__Get> {
		const {
registrationId,
} = data;
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v1/registration/reconfirm',
			query: {
				registration_id: registrationId
			},
			errors: {
				422: `Validation Error`,
			},
		});
	}

	/**
	 * Check In
	 * Check in a registration, means we display the registration data on the check-in page
 * @param current_user: User that checks in the registration
 * @param database: Database session
 * @param registration_id: ID of the registration to check in
 * @return: Registration data
	 * @returns app__schema__registration__Get Successful Response
	 * @throws ApiError
	 */
	public static checkInApiV1RegistrationCheckInRegistrationIdGet(data: TDataCheckInApiV1RegistrationCheckInRegistrationIdGet): CancelablePromise<app__schema__registration__Get> {
		const {
registrationId,
} = data;
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v1/registration/check-in/{registration_id}',
			path: {
				registration_id: registrationId
			},
			errors: {
				422: `Validation Error`,
			},
		});
	}

	/**
	 * Confirm Check In
	 * Check in a registration, means we display the registration data on the check-in page
 * @param current_user: User that checks in the registration
 * @param database: Database session
 * @param registration_id: ID of the registration to check in
 * @return: Registration data
	 * @returns app__schema__registration__Get Successful Response
	 * @throws ApiError
	 */
	public static confirmCheckInApiV1RegistrationCheckInRegistrationIdPut(data: TDataConfirmCheckInApiV1RegistrationCheckInRegistrationIdPut): CancelablePromise<app__schema__registration__Get> {
		const {
registrationId,
} = data;
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/api/v1/registration/check-in/{registration_id}',
			path: {
				registration_id: registrationId
			},
			errors: {
				422: `Validation Error`,
			},
		});
	}

}