import {Box, Divider, Grid, Group, Text, Title} from "@mantine/core";
import {useQuery} from "@tanstack/react-query";
import {StatisticsService} from "../../client";
import {DonutChart, LineChart, BarChart} from "@mantine/charts";

export const Statistics = () => {

    const {data: registrationsPerDay} = useQuery({
        queryKey: ['statistics-registrations-per-day'],
        queryFn: StatisticsService.getRegistrationApiV1StatsRegistrationGet,
    });

    const {data: food} = useQuery({
        queryKey: ['statistics-food'],
        queryFn: StatisticsService.getFoodStatsApiV1StatsFoodGet,
    });
    const {data: openRegistrations} = useQuery({
        queryKey: ['statistics-open-registrations'],
        queryFn: StatisticsService.getRegistrationsApiV1StatsRegistrationsGet,
    });

    const {data: tshirtComparison} = useQuery({
        queryKey: ['statistics-t-shirts'],
        queryFn: StatisticsService.getTshirtsApiV1StatsTshirtsGet,
    });

    const registrationsChart = registrationsPerDay && <LineChart
        h={250}
        w="100%"
        data={registrationsPerDay.filter((item) => item.count > 0) ?? []}
        withPointLabels
        dataKey="date"
        series={[
            {name: 'count', color: 'blue.3'},
        ]}
        gridAxis="y"
        curveType="natural"
    />;

    let chartItems: { color: string; name: string; value: number; }[] = [];
    if (food) {
        chartItems = food.map(item => {
            let color: string = '';
            let suffix: string = '';

            if (item.name === 'Mixed') {
                color = 'red.6';
                suffix = ' 🥩';
            } else if (item.name === 'Vegetarian') {
                color = 'yellow.6';
                suffix = ' 🥗';
            } else if (item.name === 'Vegan') {
                color = 'green.6';
                suffix = ' 🌱';
            }
            return {...item, color, name: item.name + suffix};
        }) ?? [];
    }


    const foodChart = food && <DonutChart
        w="100%"
        h={250}
        m={10}
        size={250}
        withLabelsLine
        labelsType="value"
        withLabels
        withTooltip tooltipDataSource="segment"
        startAngle={180} endAngle={0}
        data={chartItems}/>;

    const totalRegistrations = openRegistrations?.find((item) => item.name === 'Total');
    const maxRegistrations = openRegistrations?.find((item) => item.name === 'Max');

    let openReservationsChartItems: { color: string; name: string; value: number; }[] = [];
    if (openRegistrations) {
        openReservationsChartItems = openRegistrations.filter((item) => item.name !== 'Total' && item.name !== 'Max').map(item => {
            let color: string = '';

            if (item.name === 'Outstanding') {
                color = 'red.6';
            } else if (item.name === 'Free') {
                color = 'gray.6';
            } else if (item.name === 'Paid') {
                color = 'green.6';
            }
            return {...item, color};
        }) ?? [];
    }

    const openReservationsChart = openRegistrations && <DonutChart
        w="100%"
        h={250}
        mt={10}
        size={250}
        withLabelsLine
        // labelsPosition="outside"
        labelsType="value"
        withLabels
        withTooltip tooltipDataSource="segment"
        data={openReservationsChartItems}/>;

    const lastYear = new Date().getFullYear() - 1;
    const thisYear = new Date().getFullYear();

    const data = [];
    let tshirtChart = <></>;
    if (tshirtComparison) {
        const shirtSizes = Object.keys(tshirtComparison[lastYear]) as unknown as string[];

        const shirts = shirtSizes.map(size => {
            return {
                size: size.toString(), color: 'green.6',
            }
        });

        for (let shirt in shirts) {
            if (tshirtComparison) {
                data.push({
                    size: shirts[shirt].size,
                    [lastYear]: tshirtComparison[lastYear][shirts[shirt].size],
                    [thisYear]: tshirtComparison[thisYear][shirts[shirt].size]
                });
            }
        }

        tshirtChart = tshirtComparison && <BarChart
            h={300}
            data={data}
            dataKey="size"
            withBarValueLabel
            series={[
                {name: "2023", color: 'green.6'},
                {name: "2024", color: 'blue.6'},
            ]}
            tickLine="y"
        />
    }

    return (
        <Box>
            <Group justify="center" mb={5}>
                <Title fw={250}>Statistics</Title>
            </Group>
            <Box m="20">
                <Title size="md" mt="20">Registrations per day</Title>
                <Text c="dimmed" mb={10}>showing only days where something happened</Text>
                {registrationsChart}
            </Box>
            <Divider mt="20"/>
            <Grid>
                <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                    <Box m={5}>
                        <Title size="md" mt="20">Food preferences</Title>
                        {foodChart}
                    </Box>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                    <Box m={5}>
                        <Title size="md" mt="20">Reservations</Title>
                        <Text c="dimmed">Maximum {maxRegistrations?.value}, already
                            registered: {totalRegistrations?.value}</Text>
                        {openReservationsChart}
                    </Box>
                </Grid.Col>
            </Grid>
            <Divider mt="20"/>
            <Grid>
                <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                    <Box m="20">
                        <Title size="md" mt="20">T-Shirts</Title>
                        <Text c="dimmed" mb={10}>compared to last year</Text>
                        {tshirtChart}
                    </Box>
                </Grid.Col>
            </Grid>
        </Box>
    );
}