import React from 'react';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import {TextInput, Container, Button, Title, Space, Stack, Center, Image} from "@mantine/core";
import {useForm} from "@mantine/form";
import {UserService} from "../../client";
import {useAuthStore} from "../../store/authStore";

function Login() {
    const form = useForm({
        mode: 'uncontrolled',
        initialValues: {
            username: '',
            password: '',
        },
    });

    const tokenStore = useAuthStore(state => state.setToken);

    const login = (username: string, password: string) => {
        UserService.loginForAccessTokenApiV1UserLoginPost({
            formData: {
                username,
                password
            }
        }).then((token) => {
            tokenStore(token.access_token);
        }).catch((err) => {
            console.log(err);
        })
    }

    return (
        <div className="min-vh-100 min-vw-100">
            <Center>
                <Container>
                    <Space h="30vh"/>
                    <Stack w="50vw">
                        <Image
                            radius="sm"
                            // h="100"
                            w="100"
                            src="/logo.png"
                        />
                        <Title fw={250}>Login</Title>
                        <form onSubmit={form.onSubmit((values) => login(values.username, values.password))}>
                            <TextInput
                                className="w-auto"
                                placeholder="username"
                                key={form.key('username')}
                                {...form.getInputProps('username')}
                            />
                            <TextInput
                                mt="md"
                                className="w-auto"
                                placeholder="password"
                                type="password"
                                key={form.key('password')}
                                {...form.getInputProps('password')}
                            />
                            <Button
                                type="submit"
                                mt="md"
                            >
                                Login
                            </Button>
                        </form>
                    </Stack>
                </Container>
            </Center>
        </div>
    );
}

export default Login;
