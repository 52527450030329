import React, {useEffect, useState} from "react";
import {ActionIcon, Button, Group, Stack, TextInput, Title} from '@mantine/core';
import {DataTable, DataTableSortStatus} from "mantine-datatable";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {NewsletterService, Subscriber} from "../../client";
import sortBy from "lodash/sortBy";
import {closeModal, openModal} from "@mantine/modals";
import {useDebouncedValue} from "@mantine/hooks";
import {IconSearch, IconX} from "@tabler/icons-react";


export const Newsletter = () => {
    const {isLoading, data} = useQuery({
        queryKey: ['subscribers'],
        queryFn: NewsletterService.getAllSubscribersApiV1NewsletterSubscribersGet,
    });
    console.log(data);
    const [subscribers, setSubscribers] = useState<Subscriber[]>([]);
    const PAGE_SIZE = 100;

    const [sortStatus, setSortStatus] = useState<DataTableSortStatus<Subscriber>>({
        columnAccessor: 'timestamp',
        direction: 'desc',
    });

    const [page, setPage] = useState(1);
    const [records, setRecords] = useState(subscribers.slice(0, 20));
    const [query, setQuery] = useState('');
    const [debouncedQuery] = useDebouncedValue(query, 200);
    useEffect(() => {
        const sorted = (sortBy(data, sortStatus.columnAccessor) as Subscriber[]).filter((subscriber: Subscriber) => {
            if (debouncedQuery === '') {
                return true;
            }
            const lowercase: string = debouncedQuery.toLowerCase();
            return subscriber.email.toLowerCase().includes(lowercase);
        })
        const tmp = sortStatus.direction === 'desc' ? sorted.reverse() : sorted;
        setSubscribers(tmp);
        const from = (page - 1) * PAGE_SIZE;
        const to = from + PAGE_SIZE;
        setRecords(tmp.slice(from, to));
    }, [debouncedQuery, sortStatus, data, page]);

    const queryClient = useQueryClient();

    const removeSubscriber = (subscriber: Subscriber) => {
        NewsletterService.deleteSubscriberByEmailApiV1NewsletterSubscriberEmailDelete({
            email: subscriber.email,
        }).then(() => {
            queryClient.invalidateQueries({queryKey: ['subscribers']});
        });
    }

    const showModal = ({subscriber, action}: {subscriber: Subscriber, action: 'delete'}) => {
        openModal({
            modalId: action,
            title: 'Delete subscriber',
            children: (
                <>
                    <p>Are you sure you want to delete <strong>{subscriber.firstname}</strong>?</p>
                    <Stack gap="xs">
                        <Button color="red" onClick={() => removeSubscriber(subscriber)}>Yes</Button>
                        <Button onClick={() => closeModal(action)}>No</Button>
                    </Stack>
                </>
            )
        })
    };
    return (
        <>
            <Group justify="center" mb={5}>
                <Title fw={250}>Newsletter</Title>
            </Group>
            <DataTable
                withTableBorder
                striped
                highlightOnHover
                records={records}
                fetching={isLoading}
                loaderType='oval'
                sortStatus={sortStatus}
                onSortStatusChange={setSortStatus}
                page={page}
                onPageChange={(p) => setPage(p)}
                recordsPerPage={PAGE_SIZE}
                totalRecords={subscribers.length}
                columns={[
                    {accessor: 'firstname', title: 'Firstname', sortable: true},
                    {accessor: 'email', title: 'Email',
                        sortable: true,
                        filter:(
                            <TextInput
                                label="Search"
                                description="Show emails which include"
                                placeholder="Search emails..."
                                leftSection={<IconSearch size={16} />}
                                rightSection={
                                    <ActionIcon size="sm" variant="transparent" c="dimmed" onClick={() => setQuery('')}>
                                        <IconX size={14} />
                                    </ActionIcon>
                                }
                                value={query}
                                onChange={(e) => setQuery(e.currentTarget.value)}
                            />),
                        filtering: query !== '',},
                    {
                        accessor: 'actions',
                        title: '',
                        render: (record) => (
                            <Button
                                onClick={() => showModal({subscriber: record, action: 'delete'})}
                                variant="outline"
                                size="xs"
                            >
                                🗑️
                            </Button>
                        ),
                    },
                ]}
            />
        </>
    );
}