import {create} from 'zustand';
import {persist} from "zustand/middleware";
import {OpenAPI} from "../client";
import { jwtDecode } from "jwt-decode";

interface authState {
    token: string;
    setToken: (newToken: string) => void;
    logout: () => void;
    isLoggedIn: boolean;
}

export const useAuthStore = create<authState>()(
    persist(
        (set) => ({
            token: "",
            setToken: (newToken: string) => {
                OpenAPI.HEADERS = {Authorization: `Bearer ${newToken}`};
                return set({token: newToken, isLoggedIn: newToken !== ""});
            },
            logout: () => set({token: "", isLoggedIn: false}),
            isLoggedIn: false,
        }),
        {
            name: "auth-storage",
            onRehydrateStorage: () => (state) => {
                if (!state) {
                    return
                }
                const token = state.token;
                const decodedToken = jwtDecode(token);

                if (!decodedToken || !decodedToken.exp) {
                    return;
                }
                if (decodedToken.exp < Date.now() / 1000) {
                    state.setToken("");
                } else {
                    OpenAPI.HEADERS = {Authorization: `Bearer ${token}`};
                }
            },
        }
    ));
