import React, {useEffect, useRef, useState} from "react";
import {RegistrationService} from "../../client";
import {Box, Button, Group, Modal, Title, Text, TextInput, Stack} from "@mantine/core";
import QrScanner from "qr-scanner";
import {toNumber} from "lodash";
import './CheckIn.css';
import {useDebouncedValue} from "@mantine/hooks";


const QrReader = () => {
    // QR States
    const scanner = useRef<QrScanner>(null);
    const videoEl = useRef<HTMLVideoElement>(null);
    const qrBoxEl = useRef<HTMLDivElement>(null);
    const [qrOn, setQrOn] = useState<boolean>(true);

    const [scannedData, setScannedData] = useState<string | null>(null);
    const alreadyScanned = new Set<string>();


    const [isCheckInModalOpen, setCheckInModalOpen] = useState(false);
    const [isDoubleConfirmModalOpen, setDoubleConfirmModalOpen] = useState(false);
    const [checkInStatus, setCheckInStatus] = useState({
        statusText: '',
        bgColor: 'gray',
        registrationId: '',
        tshirtSize: '',
        checkConsent: false,
        disableCheckInButton: false,
    });
    const [manualInput, setManualInput] = useState('');
    const [debouncedManualInput] = useDebouncedValue(manualInput, 500);


    const scanned = (code: string) => {
        const decodedText = code;
        if (!decodedText || alreadyScanned.has(decodedText)) {
            return;
        }
        const registrationId = toNumber(decodedText);
        alreadyScanned.add(decodedText);
        setTimeout(() => alreadyScanned.delete(decodedText), 3000);

        try {
            let newStatus = {
                statusText: '',
                bgColor: 'gray',
                registrationId: '',
                tshirtSize: '',
                checkConsent: false,
                disableCheckInButton: false,
            };
            RegistrationService.checkInApiV1RegistrationCheckInRegistrationIdGet({
                registrationId
            }).then((registration) => {
                newStatus = {
                    statusText: '',
                    bgColor: 'gray',
                    registrationId: `${registration.firstname} ${registration.lastname} (${registration.age})`,
                    tshirtSize: `👕 ${registration.tshirt?.toUpperCase() || ''}`,
                    checkConsent: registration.age < 18,
                    disableCheckInButton: registration.checked_in ?? false,
                };
                if (registration.checked_in) {
                    newStatus.bgColor = 'blue';
                    newStatus.statusText = 'Already checked in';
                } else if (registration.paid === 'outstanding') {
                    newStatus.bgColor = registration.age >= 18 ? 'red' : 'yellow';
                    newStatus.statusText = registration.age >= 18 ? 'Payment required (18+)' : 'Payment required (under 18)';
                } else if (registration.paid === 'paid') {
                    newStatus.bgColor = registration.age >= 18 ? 'green' : 'blue';
                    newStatus.statusText = registration.age >= 18 ? 'Payment complete (18+)' : 'Payment complete (under 18)';
                }
                setScannedData(decodedText);
                setCheckInStatus(newStatus);
                if (newStatus.registrationId !== "") {
                    setCheckInModalOpen(true);
                }
            }).catch((err) => {})
        } catch (error) {
            alert('Check-in failed');
        }
    };

    const handleConfirmCheckIn = async () => {
        if (scannedData) {
            try {
                RegistrationService.confirmCheckInApiV1RegistrationCheckInRegistrationIdPut({
                    registrationId: toNumber(scannedData)
                }).then(() => {
                    setCheckInModalOpen(false);
                    setDoubleConfirmModalOpen(false);
                })
            } catch (error) {
                alert('Check-in failed');
            }
        }
    };

    const onScanSuccess = (result: QrScanner.ScanResult) => {
        scanned(result.data);
    };

    useEffect(() => {
        const videoElement = videoEl.current;
        if (videoElement && !scanner.current) {
            // 👉 Instantiate the QR Scanner
            scanner.current = new QrScanner(videoElement, onScanSuccess, {
                preferredCamera: "environment",
                highlightScanRegion: true,
                highlightCodeOutline: true,
                overlay: qrBoxEl?.current || undefined,
            });

            // 🚀 Start QR Scanner
            scanner?.current
                ?.start()
                .then(() => setQrOn(true))
                .catch((err) => {
                    if (err) setQrOn(false);
                });
        }

        return () => {
            if (!videoElement) {
                scanner?.current?.pause();
            }
        };
    }, []);

    useEffect(() => {
        if (!qrOn)
            alert(
                "Camera is blocked or not accessible. Please allow camera in your browser permissions and Reload."
            );
    }, [qrOn]);

    useEffect(() => {
        onScanSuccess({
            data: debouncedManualInput,
            cornerPoints: []
        })
    }, [debouncedManualInput]);

    return (
        <>
            <Box className="qr-reader" h="60%">
                <video ref={videoEl} />
            </Box>
            {/* Check-in Modal */}
            <Modal
                opened={isCheckInModalOpen}
                centered
                onClose={() => setCheckInModalOpen(false)}
                title="Check-in"
                styles={{
                    header: { backgroundColor: checkInStatus.bgColor, color: 'black' },
                }}
                transitionProps={{ transition: 'rotate-left' }}
            >
                <Box>
                    <Text mt={5}>{checkInStatus.registrationId}</Text>
                    <Text mt={5}>{checkInStatus.tshirtSize}</Text>
                    {checkInStatus.checkConsent && <Text c="red">Parental consent required!</Text>}
                    <Text mt={5}>{checkInStatus.statusText}</Text>
                    <Group justify="space-between" mt={5}>
                        <Button color="gray" onClick={() => setCheckInModalOpen(false)}>Close</Button>
                        <Button
                            color="blue"
                            disabled={checkInStatus.disableCheckInButton}
                            onClick={() => setDoubleConfirmModalOpen(true)}
                        >
                            Confirm Check-in
                        </Button>
                    </Group>
                </Box>
            </Modal>

            {/* Double Confirm Modal */}
            <Modal
                opened={isDoubleConfirmModalOpen}
                centered
                onClose={() => setDoubleConfirmModalOpen(false)}
                transitionProps={{ transition: 'rotate-left' }}
                title="Are you sure?"
                styles={{
                    header: { backgroundColor: checkInStatus.bgColor, color: 'black' },
                }}
            >
                <Group justify="space-between" mt={5}>
                    <Button color="gray" onClick={() => setDoubleConfirmModalOpen(false)}>Cancel</Button>
                    <Button color="blue" onClick={handleConfirmCheckIn}>Confirm</Button>
                </Group>
            </Modal>
            <Stack>
                <Text mt={5}>Manual check-in if scanner does not work</Text>
                <TextInput
                    type="number"
                    pattern="[0-9]*"
                    value={manualInput}
                    onChange={(e) => setManualInput(e.target.value)}
                >
                </TextInput>
                <Text mt={2} c="dimmed">will automatically check if you stop writing</Text>
            </Stack>
        </>
    );
};

const CheckInComponent: React.FC = () => {
    return (
        <>
            <Stack justify="center">
                <Text size="lg" c="dimmed">Scan the QR code below to check in:</Text>
                <QrReader/>
            </Stack>
        </>
    );
};

export const CheckIn = () => {
    return (<>
        <Box>
            <Group justify="center" mb={5}>
                <Title fw={250}>Check-In</Title>
            </Group>
            <Box>
                <CheckInComponent/>
            </Box>
        </Box>
    </>);
}