import {Button, useMantineTheme, rgba, Box, Title, Group, TextInput, Checkbox} from '@mantine/core';
import {Registration, RegistrationService} from '../../client';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import {DataTable, DataTableSortStatus} from 'mantine-datatable';
import sortBy from 'lodash/sortBy';
import {useEffect, useState} from "react";
import {useDebouncedValue, useMediaQuery} from "@mantine/hooks";
import {ActionIcon} from "@mantine/core";
import {IconX, IconSearch} from "@tabler/icons-react";

export const Registrations = () => {
    const {isLoading, data} = useQuery({
        queryKey: ['registrations'],
        queryFn: RegistrationService.getAllRegistrationsApiV1RegistrationsGet,
    });

    const isMobile = useMediaQuery('(max-width: 768px)');
    const [registrations, setRegistrations] = useState<Registration[]>([]);
    const PAGE_SIZE = 20;

    const queryClient = useQueryClient();
    const theme = useMantineTheme();

    const togglePaid = (registration: Registration) => {
        RegistrationService.togglePaidApiV1RegistrationRegIdTogglePaidPut({
            regId: registration.id,
        }).then(() => {
            queryClient.invalidateQueries({queryKey: ['registrations']});
        });
    };
    //
    // const deleteRegistration = (registration: Registration) => {
    //     RegistrationService.deleteRegistrationByIdApiV1RegistrationRegIdDelete(
    //         {
    //             regId: registration.id,
    //         }
    //     ).then(() => {
    //         queryClient.invalidateQueries({queryKey: ['registrations']});
    //     })
    // }

    const [sortStatus, setSortStatus] = useState<DataTableSortStatus<Registration>>({
        columnAccessor: 'timestamp',
        direction: 'desc',
    });

    const [page, setPage] = useState(1);
    const [records, setRecords] = useState(registrations.slice(0, 20));
    const [nameQuery, setNameQuery] = useState('');
    const [mailQuery, setMailQuery] = useState('');
    const [idQuery, setIdQuery] = useState('');
    const [commentQuery, setCommentQuery] = useState('');
    const [onlyShowPaid, toggleOnlyShowPaid] = useState(false);
    const [debouncedNameQuery] = useDebouncedValue(nameQuery, 200);
    const [debouncedMailQuery] = useDebouncedValue(mailQuery, 200);
    const [debouncedIdQuery] = useDebouncedValue(idQuery, 200);
    const [debouncedCommentQuery] = useDebouncedValue(commentQuery, 200);

    useEffect(() => {
        const sorted = sortBy(data, sortStatus.columnAccessor) as Registration[];
        const mapped = sorted
            .filter((registration: Registration) => {
                if (debouncedNameQuery === '') {
                    return true;
                }
                const lowercase: string = debouncedNameQuery.toLowerCase();
                const name: string = registration.firstname.toLowerCase();
                const lastname: string = registration.lastname.toLowerCase();
                return name.includes(lowercase) || lastname.includes(lowercase) || `${lastname} ${name}`.includes(lowercase) || `${name} ${lastname}`.includes(lowercase);
            })
            .filter((registration: Registration) => {
                if (debouncedMailQuery === '') {
                    return true;
                }
                const lowercase: string = debouncedMailQuery.toLowerCase();
                const mail: string = registration.email.toLowerCase();
                return mail.includes(lowercase);
            })
            .filter((registration: Registration) => {
                if (debouncedIdQuery === '') {
                    return true;
                }
                return registration.id.toString().includes(debouncedIdQuery);
            })
            .filter((registration: Registration) => {
                if (debouncedCommentQuery === '') {
                    return true;
                }
                return registration.comment?.toLowerCase().includes(debouncedCommentQuery.toLowerCase());
            })
            .filter((registration: Registration) => {
                if (!onlyShowPaid) {
                    return true;
                }
                return registration.paid === 'paid';
            })
            .map((registration: Registration) => ({
                ...registration,
                name: `${registration.lastname}, ${registration.firstname}`,
                address: `${registration.address}, ${registration.city}`
            }));
        const tmp = sortStatus.direction === 'desc' ? mapped.reverse() : mapped;
        setRegistrations(tmp);
        const from = (page - 1) * PAGE_SIZE;
        const to = from + PAGE_SIZE;
        setRecords(tmp.slice(from, to));
    }, [debouncedNameQuery, sortStatus, data, page, debouncedMailQuery, debouncedIdQuery, debouncedCommentQuery, onlyShowPaid]);


    return (
        <Box>
            <Group justify="center" mb={5}>
                <Title fw={250}>Registrations</Title>
            </Group>
            <DataTable
                withTableBorder
                striped
                highlightOnHover
                records={records}
                fetching={isLoading}
                loaderType='oval'
                sortStatus={sortStatus}
                onSortStatusChange={setSortStatus}
                page={page}
                onPageChange={(p) => setPage(p)}
                recordsPerPage={PAGE_SIZE}
                totalRecords={registrations.length}
                columns={[
                    {
                        accessor: 'id',
                        title: 'ID',
                        sortable: true,
                        filter: (
                            <TextInput
                                label="Filter"
                                description="Filter by ID"
                                placeholder="Filter registrations..."
                                leftSection={<IconSearch size={16}/>}
                                rightSection={
                                    <ActionIcon size="sm" variant="transparent" c="dimmed"
                                                onClick={() => setIdQuery('')}>
                                        <IconX size={14}/>
                                    </ActionIcon>
                                }
                                value={idQuery}
                                onChange={(e) => setIdQuery(e.currentTarget.value)}
                            />),
                    },
                    {
                        accessor: 'timestamp', title: 'Timestamp', sortable: true, hidden: isMobile,
                        render: ({timestamp}) => {
                            if (timestamp) {
                                const split: string[] = timestamp.split(':');
                                return `${split[0].replace('T', ' at ')}:${split[1]}`
                            }
                        }
                    },
                    {
                        accessor: 'name',
                        title: 'Name',
                        sortable: true,
                        filter: (
                            <TextInput
                                label="Filter"
                                description="Filter by Name"
                                placeholder="Filter registrations..."
                                leftSection={<IconSearch size={16}/>}
                                rightSection={
                                    <ActionIcon size="sm" variant="transparent" c="dimmed"
                                                onClick={() => setNameQuery('')}>
                                        <IconX size={14}/>
                                    </ActionIcon>
                                }
                                value={nameQuery}
                                onChange={(e) => setNameQuery(e.currentTarget.value)}
                            />),
                        filtering: nameQuery !== '',
                    },
                    {
                        accessor: 'age',
                        title: 'Age',
                        sortable: true,
                        hidden: isMobile,
                    },
                    {
                        accessor: 'email',
                        title: 'Email',
                        render: ({email}) => (
                            <a href={`mailto:${email}`}>{email}</a>
                        ),
                        hidden: isMobile,
                        filter: (
                            <TextInput
                                label="Filter"
                                description="Filter by mail"
                                placeholder="Filter registrations..."
                                leftSection={<IconSearch size={16}/>}
                                rightSection={
                                    <ActionIcon size="sm" variant="transparent" c="dimmed"
                                                onClick={() => setMailQuery('')}>
                                        <IconX size={14}/>
                                    </ActionIcon>
                                }
                                value={mailQuery}
                                onChange={(e) => setMailQuery(e.currentTarget.value)}
                            />),
                        filtering: mailQuery !== '',
                    },
                    {
                        accessor: 'phone',
                        title: 'Phone',
                        render: ({phone}) => {
                            const withPlus = `+${phone}`.replace("++", '+');
                            return <a href={`tel:${withPlus}`}>{withPlus}</a>;
                        },
                        hidden: isMobile,
                    },
                    {
                        accessor: 'address',
                        title: 'Address',
                        sortable: true,
                        hidden: isMobile,
                    },
                    {
                        accessor: 'food',
                        title: 'Food',
                        sortable: true,
                        hidden: isMobile,
                    },
                    {
                        accessor: 'tshirt',
                        title: 'T-Shirt',
                        sortable: true,
                        hidden: isMobile,
                        render: ({tshirt: tShirt}) => tShirt?.toUpperCase() ?? '',
                    },
                    {
                        accessor: 'picture',
                        title: 'Picture',
                        render: ({picture}) => (picture ? '✅' : '🚫'),
                        sortable: true,
                        hidden: isMobile,
                    },
                    {
                        accessor: 'comment',
                        title: 'Comment',
                        filter: (
                            <TextInput
                                label="Filter"
                                description="Filter by Comment"
                                placeholder="Filter registrations..."
                                leftSection={<IconSearch size={16}/>}
                                rightSection={
                                    <ActionIcon size="sm" variant="transparent" c="dimmed"
                                                onClick={() => setCommentQuery('')}>
                                        <IconX size={14}/>
                                    </ActionIcon>
                                }
                                value={commentQuery}
                                onChange={(e) => setCommentQuery(e.currentTarget.value)}
                            />),
                    },
                    {
                        accessor: 'checked_in',
                        title: 'Checked-In',
                        render: ({checked_in}) => (checked_in ? '✅' : '❌'),
                        sortable: true,
                        hidden: isMobile,
                    },
                    {
                        accessor: 'toggle-paid',
                        title: '',
                        render: (record) => (
                            <Button
                                onClick={() => togglePaid(record)}
                                variant="outline"
                                size="xs"
                                color={record.paid === 'paid' ? 'red' : 'green'}
                            >
                                💰
                            </Button>
                        ),
                        filter: (
                            <Checkbox
                                label="Only show paid registrations."
                                checked={onlyShowPaid}
                                onChange={(e) => toggleOnlyShowPaid(e.currentTarget.checked)}
                            />),
                    }
                    // ,
                    // {
                    //     accessor: 'delete-registration',
                    //     title: '',
                    //     render: (record) => (
                    //             <Button
                    //                 onClick={() => {
                    //                     if (window.confirm("Are you sure you want to delete this registration?")) {
                    //                         deleteRegistration(record);
                    //                     }
                    //                 }}
                    //                 variant="outline"
                    //                 size="xs"
                    //                 color='red'
                    //             >
                    //                 🗑️
                    //             </Button>
                    //     ),
                    // },
                ]}
                rowStyle={({paid}) => ({
                    backgroundColor: paid === 'paid' ? rgba(theme.colors.green[8], 0.25) : undefined,
                })}
            />
        </Box>
    );
};
