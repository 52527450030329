import {
    ActionIcon,
    Box,
    Button,
    Checkbox, Code,
    CopyButton,
    Group,
    Modal,
    Space,
    Stack,
    Text,
    TextInput,
    Title, Tooltip
} from '@mantine/core';
import {AppUser, UserAdd, UserService} from '../../client';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import {DataTable, DataTableSortStatus} from 'mantine-datatable';
import sortBy from 'lodash/sortBy';
import React, {useEffect, useState} from "react";
import {useAuthStore} from "../../store/authStore";
import {jwtDecode} from "jwt-decode";
import {closeModal, openModal} from '@mantine/modals';
import {DateInput} from "@mantine/dates";
import {IconCheck, IconCopy} from '@tabler/icons-react';

export const Users = () => {
        const {isLoading, data} = useQuery({
            queryKey: ['users'],
            queryFn: UserService.getAllUsersApiV1UsersGet,
        });

        const [users, setUsers] = useState<AppUser[]>([]);
        const PAGE_SIZE = 20;

        const queryClient = useQueryClient();

        const token = useAuthStore(state => state.token);
        const currentUser: string = jwtDecode(token).sub ?? '';


        const [isPasswordChangeModalOpen, setIsPasswordChangeModalOpen] = React.useState(false);
        const [newPassword, setNewPassword] = React.useState('');
        const [confirmPassword, setConfirmPassword] = React.useState('');
        const [changePasswordUser, setChangePasswordUser] = React.useState('');
        const [suggestedPassword, setSuggestedPassword] = React.useState('');

        const {data: admin} = useQuery({
            queryKey: ['is-admin'],
            queryFn: UserService.checkIfUserIsAdminApiV1UserCheckIsAdminGet
        });

        const isAdmin = admin ?? false;

        const deleteAppUser = (user: AppUser) => {
            UserService.deleteUserByUsernameApiV1UserUsernameDelete({
                username: user.username
            }).then(() => {
                queryClient.invalidateQueries({queryKey: ['users']}).then(r => closeModal("delete"));
            });
        }

        const setActiveUntil = (user: AppUser) => {
            if (!user.active_until) {
                return;
            }
            UserService.updateDeactivationDateForUserApiV1UserChangeDateUseridNewDatePut({
                userid: user.id,
                newDate: new Date(user.active_until).toISOString().split('T')[0]
            }).then(() => {
                queryClient.invalidateQueries({queryKey: ['users']}).then(() => closeModal("activeUntil"));
                ;
            });
        };

        const addUser = (user: UserAdd) => {
            if (!user.username || !user.email || !user.password) {
                return;
            }
            UserService.addUserApiV1UserPost({
                requestBody: user
            }).then(() => {
                queryClient.invalidateQueries({queryKey: ['users']}).then(() => closeModal("add"));
            });
        }

        const showAddModal = ({user, action}: { user: UserAdd; action: 'add' }) => {
            openModal({
                modalId: action,
                title: 'Add User',
                children: (
                    <>
                        <TextInput label="Username" required placeholder="username" onChange={(e) => {
                            user.username = e.target.value;
                        }}/>
                        <TextInput label="E-mail" required placeholder="e@mail.com" onChange={(e) => {
                            user.email = e.target.value;
                        }}/>
                        <TextInput label="Password" required placeholder="password" type="password" onChange={(e) => {
                            user.password = e.target.value;
                        }}/>
                        <Group m="xs">
                            <Checkbox label="Admin" onChange={(e) => {
                                user.is_admin = e.target.checked;
                            }}/>
                            <Checkbox label="Active" onChange={(e) => {
                                user.is_active = e.target.checked;
                            }}/>
                        </Group>
                        <DateInput
                            label="Active until"
                            placeholder="Select date"
                            onChange={(date) => {
                                user.active_until = date?.toISOString() ?? '';
                            }}
                        />
                        <Button type="submit" variant="outline" mt="xs" onClick={() => addUser(user)}>
                            Add
                        </Button>
                    </>
                ),
            });
        };

        const generateRandomString = (): string => {
            let outString: string = '';
            let inOptions: string = 'abcdefghijklmnopqrstuvwxyz0123456789@!#&^';

            for (let i = 0; i < 16; i++) {

                outString += inOptions.charAt(Math.floor(Math.random() * inOptions.length));

            }

            return outString;
        }


        const changePasswordButton = (record: AppUser) => {
            return <>
                <Button size="xs" variant="outline" color="orange" onClick={() => {
                    setChangePasswordUser(record.username);
                    setSuggestedPassword(generateRandomString());
                    setIsPasswordChangeModalOpen(true);
                }}>
                    Change password
                </Button>
                {/* Change password Modal */}
                <Modal
                    opened={isPasswordChangeModalOpen}
                    centered
                    onClose={() => setIsPasswordChangeModalOpen(false)}
                    title="Change password"
                    transitionProps={{transition: 'rotate-left'}}
                >
                    <Box>
                        <Text mt={5}>Change password of {record.username}</Text>
                        <Group m={5} ml={0} c="dimmed">
                            <Text mt={4}>Suggestion: </Text><Code>{suggestedPassword}</Code>
                            <CopyButton value={suggestedPassword} timeout={2000}>
                                {({copied, copy}) => (
                                    <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow
                                             position="right">
                                        <ActionIcon color={copied ? 'teal' : 'gray'}
                                                    variant="subtle" onClick={copy}>
                                            {copied ? <IconCheck size={16}/> :
                                                <IconCopy size={16}/>}
                                        </ActionIcon>
                                    </Tooltip>
                                )}
                            </CopyButton>
                        </Group>

                        <TextInput
                            label="New password"
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.currentTarget.value)}
                        />
                        <TextInput
                            label="Confirm password"
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.currentTarget.value)}
                        />
                        <Button
                            mt={10}
                            disabled={newPassword === '' || confirmPassword !== newPassword}
                            onClick={() => {
                                UserService.updatePasswordByUsernameApiV1UserPasswordUsernamePut({
                                    username: changePasswordUser,
                                    requestBody: {
                                        username: changePasswordUser,
                                        password: confirmPassword
                                    }
                                }).then(() => {
                                    setChangePasswordUser("");
                                    setConfirmPassword("");
                                    setNewPassword("");
                                    setIsPasswordChangeModalOpen(false);
                                });
                            }}
                        >
                            Change password
                        </Button>
                    </Box>
                </Modal>
            </>
        };


        const showModal = ({user, action}: { user: AppUser; action: 'activeUntil' | 'delete' }) => {
            openModal({
                modalId: action,
                title:
                    action === 'activeUntil'
                        ? 'Change active until'
                        : action === 'delete'
                            ? 'Delete user'
                            : '',
                children: (
                    (action === 'activeUntil' && <>
                        <DateInput
                            label="Active until"
                            required
                            placeholder="Select date"
                            onChange={(date) => {
                                user.active_until = date?.toISOString() ?? '';
                            }}
                            value={user.active_until ? new Date(user.active_until) : new Date()}
                        />
                        <Button type="submit" variant="outline" mt="xs" onClick={() => setActiveUntil(user)}>
                            Yes, change
                        </Button>
                    </>)
                    || (action === 'delete' && <>
                        <p>Are you sure you want to delete <strong>{user.username}</strong>?</p>
                        <Stack gap="xs">
                            <Button color="red" onClick={() => deleteAppUser(user)}>Yes</Button>
                            <Button onClick={() => closeModal(action)}>No</Button>
                        </Stack>
                    </>)
                ),
            });
        };


        const toggleActive = (user: AppUser) => {
            UserService.toggleActiveUserApiV1UserToggleActiveUseridPut({
                userid: user.id
            }).then(() => {
                queryClient.invalidateQueries({queryKey: ['users']}).then(r => {
                });
            });
        }

        const [sortStatus, setSortStatus] = useState<DataTableSortStatus<AppUser>>({
            columnAccessor: 'username',
            direction: 'desc',
        });

        const [page, setPage] = useState(1);
        const [records, setRecords] = useState(users.slice(0, 20));

        useEffect(() => {
            const sorted = sortBy(data, sortStatus.columnAccessor) as AppUser[];
            const tmp = sortStatus.direction === 'desc' ? sorted.reverse() : sorted;
            setUsers(tmp);
            const from = (page - 1) * PAGE_SIZE;
            const to = from + PAGE_SIZE;
            setRecords(tmp.slice(from, to));
        }, [sortStatus, data, page]);

        if (!isAdmin) {
            return <></>;
        }

        return (
            <>
                <Group justify="center" mb={5}>
                    <Title fw={250}>Users</Title>
                </Group>
                <DataTable
                    mt={10}
                    withTableBorder
                    striped
                    highlightOnHover
                    records={records}
                    fetching={isLoading}
                    loaderType='oval'
                    sortStatus={sortStatus}
                    onSortStatusChange={setSortStatus}
                    page={page}
                    onPageChange={(p) => setPage(p)}
                    recordsPerPage={PAGE_SIZE}
                    totalRecords={users.length}
                    columns={[
                        {accessor: 'username', title: 'Username', sortable: true},
                        {
                            accessor: 'active',
                            title: 'Active',
                            render: ({is_active}) => (is_active ? '✅' : '🚫'),
                            sortable: true,
                        },
                        {
                            accessor: 'is_admin',
                            title: 'Admin',
                            render: ({is_admin}) => (is_admin ? '✅' : '❌'),
                            sortable: true,
                        },
                        {
                            accessor: 'active_until',
                            title: 'Active Until',
                            render: ({active_until}) => {
                                if (active_until) {
                                    return new Date(active_until).toLocaleDateString();
                                }
                                return 'N/A';
                            },
                        },
                        {
                            accessor: 'actions',
                            title: '',
                            render: (record) => {
                                if (record.username === currentUser) {
                                    return <Group>
                                        <Stack ml={10}>
                                            <Space/>
                                            <>🔒</>
                                            <Space/>
                                        </Stack>
                                        {changePasswordButton(record)}
                                    </Group>;
                                } else {
                                    return (
                                        <>
                                            <Button
                                                onClick={() => showModal({user: record, action: 'delete'})}
                                                variant="outline"
                                                ml="xs"
                                                size="xs"
                                            >
                                                🗑️
                                            </Button>
                                            <Button variant="outline"
                                                    ml="xs"
                                                    size="xs"
                                                    onClick={() => showModal({user: record, action: 'activeUntil'})}>
                                                📆
                                            </Button>
                                            <Button
                                                onClick={() => toggleActive(record)}
                                                variant="outline"
                                                color={record.is_active ? 'red' : 'green'}
                                                m="xs"
                                                size="xs"
                                            >
                                                {record.is_active ? 'Deactivate' : 'Activate'}
                                            </Button>
                                            {record.is_active ? changePasswordButton(record) : <></>}
                                        </>
                                    );
                                }
                            },
                        },
                    ]}
                />
                <Group mt={10}>
                    <Button onClick={() => showAddModal({user: {} as UserAdd, action: 'add'})}>Add User</Button>
                    <Space/>
                </Group>
            </>
        );
    }
;
